import axios from '../../config/axios';

import { API_URL } from '../../config';

import type {
  Saldo,
  SaldoDetalle,
  NewSaldoFormData,
  SaldosQueryParams,
  EditSaldoFormData,

  // Estados
  EstadoSaldo,
  EstadoSaldoQueryParams,

  // Ingresos
  IngresoSaldo,
  IngresoSaldoQueryParams,
  NewIngresoSaldoFormData,
  EditIngresoSaldoFormData,

  // Gastos
  GastoSaldo,
  GastoSaldoQueryParams,
  NewGastoSaldoFormData,
  EditGastoBalanceFormData
} from '../../interfaces/saldo/saldo';
import type { PaginatedResponse } from '../../interfaces/api';

const BASE_URL = `${API_URL}/contable` as const;
const SALDOS_URL = `${BASE_URL}/saldos` as const;

/* ---------------------------------------
                  SALDOS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de lotes de saldos que puede ver el usuario
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<Saldo>`.
 */
const getSaldos = async (params: SaldosQueryParams = {}) => {
  return await axios.get<PaginatedResponse<Saldo>>(SALDOS_URL, { params }).then((response) => {
    return response.data;
  });
};

/**
 * @description Recupera desde la API el lote de saldo solicitado
 * @param loteId id del lote a recuperar
 * @returns Un objeto de tipo `SaldoDetalle`.
 * @throws si el usuario no tiene permisos para ver el lote de saldo.
 * @throws si el lote no existe.
 */
const getSaldo = async (loteId: number) => {
  return await axios.get<{ data: SaldoDetalle }>(`${SALDOS_URL}/${loteId}`).then((response) => {
    return response.data.data;
  });
};

/**
 * @description Registra en la api un nuevo lote de saldo
 * @param data datos del lote a crear
 * @returns Un objeto de tipo `SaldoDetalle`.
 * @throws si el usuario no tiene permisos para crear el lote.
 */
const newSaldo = async (data: NewSaldoFormData) => {
  return await axios.post<SaldoDetalle>(SALDOS_URL, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza en la api los datos de lote de saldo
 * @param loteId id del lote a editar
 * @param data datos del lote a editar
 * @returns Un objeto de tipo `SaldoDetalle`.
 * @throws si el usuario no tiene permisos para editar el lote.
 */
const editSaldo = async (loteId: number, data: EditSaldoFormData) => {
  return await axios.patch<SaldoDetalle>(`${SALDOS_URL}/${loteId}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina en la api el lote de saldo indicado (soft-delete)
 * @param loteId id del lote a eliminar
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para eliminar el lote.
 * @throws si el lote no existe.
 * @throws si lote no está en estado borrador.
 */
const deleteSaldo = async (loteId: number) => {
  return await axios.delete<Saldo>(`${SALDOS_URL}/${loteId}`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
          ESTADOS DE SALDOS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de estados de lotes de saldo que puede ver el usuario
 */
const getSaldosEstados = async (params: EstadoSaldoQueryParams) => {
  return await axios
    .get<{ data: EstadoSaldo[] }>(`${API_URL}/contable/saldos-estados`, { params })
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
      INGRESOS DE SALDOS
 ---------------------------------------- */

const INGRESOS_SALDO_URL = `${BASE_URL}/ingresos-saldo` as const;

/**
 * @description Recupera desde la API el listado de ingresos de saldo que puede ver el usuario
 * @param saldoId id del balance
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<IngresoSaldo>`.
 */
const getIngresosSaldo = async (saldoId: number, params: IngresoSaldoQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<IngresoSaldo>>(`${INGRESOS_SALDO_URL}`, {
      params: { ...params, saldo_id: saldoId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el ingreso de saldo solicitado
 * @param ingresoId id del ingreso a recuperar
 * @returns Un objeto de tipo `IngresoSaldo`.
 * @throws si el usuario no tiene permisos para ver el ingreso de saldo.
 * @throws si el ingreso no existe.
 */
const getIngresoSaldo = async (ingresoId: number) => {
  return await axios
    .get<{ data: IngresoSaldo }>(`${INGRESOS_SALDO_URL}/${ingresoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo ingreso de saldo
 * @param saldoId id del balance
 * @param data datos del ingreso a crear
 * @returns Un objeto de tipo `IngresoSaldo`.
 * @throws si el usuario no tiene permisos para crear el ingreso.
 */
const newIngresoSaldo = async (saldoId: number, data: NewIngresoSaldoFormData) => {
  return await axios
    .post<IngresoSaldo>(`${INGRESOS_SALDO_URL}`, { ...data, saldo_id: saldoId })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos del ingreso de saldo
 * @param ingresoId id del ingreso a editar
 * @param data datos del ingreso a editar
 * @returns Un objeto de tipo `IngresoSaldo`.
 * @throws si el usuario no tiene permisos para editar el ingreso.
 */
const editIngresoSaldo = async (ingresoId: number, data: EditIngresoSaldoFormData) => {
  return await axios
    .patch<IngresoSaldo>(`${INGRESOS_SALDO_URL}/${ingresoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el ingreso de saldo indicado (soft-delete)
 * @param ingresoId id del ingreso a eliminar
 * @returns Un objeto de tipo `IngresoSaldo`.
 * @throws si el usuario no tiene permisos para eliminar el ingreso.
 * @throws si el ingreso no existe.
 */
const deleteIngresoSaldo = async (ingresoId: number) => {
  return await axios.delete<IngresoSaldo>(`${INGRESOS_SALDO_URL}/${ingresoId}`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
            GASTOS DE SALDOS
 ---------------------------------------- */

const GASTOS_SALDO_URL = `${BASE_URL}/gastos-saldo` as const;

/**
 * @description Recupera desde la API el listado de gastos de saldo que puede ver el usuario
 * @param saldoId id del balance
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<GastoSaldo>`.
 */
const getGastosSaldo = async (saldoId: number, params: GastoSaldoQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<GastoSaldo>>(`${GASTOS_SALDO_URL}`, {
      params: { ...params, saldo_id: saldoId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el gasto de saldo solicitado
 * @param gastoId id del gasto a recuperar
 * @returns Un objeto de tipo `GastoSaldo`.
 * @throws si el usuario no tiene permisos para ver el gasto de saldo.
 * @throws si el gasto no existe.
 */
const getGastoSaldo = async (gastoId: number) => {
  return await axios
    .get<{ data: GastoSaldo }>(`${GASTOS_SALDO_URL}/${gastoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo gasto de saldo
 * @param saldoId id del balance
 * @param data datos del gasto a crear
 * @returns Un objeto de tipo `GastoSaldo`.
 * @throws si el usuario no tiene permisos para crear el gasto.
 */
const newGastoSaldo = async (saldoId: number, data: NewGastoSaldoFormData) => {
  return await axios
    .post<GastoSaldo>(`${GASTOS_SALDO_URL}`, { ...data, saldo_id: saldoId })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Actualiza en la api los datos del gasto de saldo
 * @param gastoId id del gasto a editar
 * @param data datos del gasto a editar
 * @returns Un objeto de tipo `GastoSaldo`.
 * @throws si el usuario no tiene permisos para editar el gasto.
 */
const editGastoSaldo = async (gastoId: number, data: EditGastoBalanceFormData) => {
  return await axios.patch<GastoSaldo>(`${GASTOS_SALDO_URL}/${gastoId}`, data).then((response) => {
    return response.data;
  });
};

/**
 * @description Elimina en la api el gasto de saldo indicado (soft-delete)
 * @param gastoId id del gasto a eliminar
 * @returns Un objeto de tipo `GastoSaldo`.
 * @throws si el usuario no tiene permisos para eliminar el gasto.
 * @throws si el gasto no existe.
 */
const deleteGastoSaldo = async (gastoId: number) => {
  return await axios.delete<GastoSaldo>(`${GASTOS_SALDO_URL}/${gastoId}`).then((response) => {
    return response.data;
  });
};

const exportSaldos = async (
  params: SaldosQueryParams & { separator?: string; id?: number } = {}
) => {
  return await axios
    .get(`${API_URL}/exportar/saldos`, { params, responseType: 'blob' })
    .then((response) => {
      return response.data;
    });
};

export const SaldoService = {
  getSaldos,
  getSaldo,
  newSaldo,
  editSaldo,
  deleteSaldo,

  exportSaldos,

  getSaldosEstados,

  getIngresosSaldo,
  getIngresoSaldo,
  newIngresoSaldo,
  editIngresoSaldo,
  deleteIngresoSaldo,

  getGastosSaldo,
  getGastoSaldo,
  newGastoSaldo,
  editGastoSaldo,
  deleteGastoSaldo
};

export default SaldoService;
