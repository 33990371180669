import axios from '../../config/axios';

import { API_URL } from '../../config';

import type {
  Balance,
  EstadoBalance,
  BalanceDetalle,
  NewBalanceFormData,
  BalancesQueryParams,
  EditBalanceFormData,
  EstadoBalanceQueryParams,
  IngresoBalanceQueryParams,
  IngresoBalance,
  EditIngresoBalanceFormData,
  NewIngresoBalanceFormData,
  GastoBalance,
  NewGastoBalanceFormData,
  EditGastoBalanceFormData
} from '../../interfaces/balance/balance';

import {
  TipoCuentaBceBalance,
  EditCuentaBceBalanceFormData,
  NewTipoCuentaBceBalanceFormData,
  TipoCuentaBceBalanceQueryParams
} from '../../interfaces/balance/tipoCuentaBceBalance';

import {
  TipoInversionBalance,
  TipoInversionBalanceQueryParams,
  NewTipoInversionBalanceFormData,
  EditTipoInversionBalanceFormData
} from '../../interfaces/balance/tipoInversionBalance';

import {
  TipoBienUsoBalance,
  TipoBienUsoBalanceQueryParams,
  NewTipoBienUsoBalanceFormData,
  EditTipoBienUsoBalanceFormData
} from '../../interfaces/balance/tipoBienUsoBalance';

import {
  TipoIntangibleBalance,
  TipoIntangibleBalanceQueryParams,
  NewTipoIntangibleBalanceFormData,
  EditTipoIntangibleBalanceFormData
} from '../../interfaces/balance/tipoIntangibleBalance';

import {
  TipoResultadoFinancieroBalance,
  TipoResultadoFinancieroBalanceQueryParams,
  NewTipoResultadoFinancieroBalanceFormData,
  EditTipoResultadoFinancieroBalanceFormData
} from '../../interfaces/balance/tipoResultadoFinancieroBalance';

import {
  TipoMonedaExtranjeraBalance,
  EditMonedaExtranjeraBalanceFormData,
  TipoMonedaExtranjeraBalanceQueryParams,
  NewTipoMonedaExtranjeraBalanceFormData
} from '../../interfaces/balance/tipoMonedaExtranjeraBalance';

import {
  TipoCajaBancoBalance,
  EditCajaBancoBalanceFormData,
  TipoCajaBancoBalanceQueryParams,
  NewTipoCajaBancoBalanceFormData
} from '../../interfaces/balance/tipoCajaBancoBalance';

import type { PaginatedResponse } from '../../interfaces/api';

const BASE_URL = `${API_URL}/contable`;

/* ---------------------------------------
                  BALANCES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de lotes de balances que puede ver el usuario
 * @param params parametros de la query
 * @returns Un objeto de tipo `PaginatedResponse<Balance>`.
 */
const getBalances = async (params: BalancesQueryParams = {}) => {
  return await axios
    .get<PaginatedResponse<Balance>>(BASE_URL + '/balances', { params })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el lote de balance solicitado
 * @param loteId id del lote a recuperar
 * @returns Un objeto de tipo `BalanceDetalle`.
 * @throws si el usuario no tiene permisos para ver el lote de balance.
 * @throws si el lote no existe.
 */
const getBalance = async (loteId: number) => {
  return await axios
    .get<{ data: BalanceDetalle }>(`${BASE_URL}/balances/${loteId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Registra en la api un nuevo lote de balance
 * @param data datos del lote a crear
 * @returns Un objeto de tipo `BalanceDetalle`.
 * @throws si el usuario no tiene permisos para crear el lote.
 */
const newBalance = async (data: NewBalanceFormData) => {
  return await axios.post<BalanceDetalle>(BASE_URL + '/balances', data).then((response) => {
    return response.data;
  });
};

/**
 * @description Actualiza en la api los datos de lote de balance
 * @param loteId id del lote a editar
 * @param data datos del lote a editar
 * @returns Un objeto de tipo `BalanceDetalle`.
 * @throws si el usuario no tiene permisos para editar el lote.
 */
const editBalance = async (loteId: number, data: EditBalanceFormData) => {
  return await axios
    .patch<BalanceDetalle>(`${BASE_URL}/balances/${loteId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el lote de balance indicado (soft-delete)
 * @param loteId id del lote a eliminar
 * @returns Un objeto de tipo `LoteDetalle`.
 * @throws si el usuario no tiene permisos para eliminar el lote.
 * @throws si el lote no existe.
 * @throws si lote no está en estado borrador.
 */
const deleteBalance = async (loteId: number) => {
  return await axios.delete<Balance>(`${BASE_URL}/balances/${loteId}`).then((response) => {
    return response.data;
  });
};

/* ---------------------------------------
          ESTADOS DE BALANCES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de estados de lotes de balance que puede ver el usuario
 */
const getBalancesEstados = async (params: EstadoBalanceQueryParams) => {
  return await axios
    .get<{ data: EstadoBalance[] }>(`${API_URL}/contable/balances-estados`, { params })
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
              INGRESOS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de ingresos del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<IngresoBalance[]>`.
 * @throws si el usuario no tiene permisos para ver los ingresos del lote de balance.
 */
const getIngresosBalance = async (balanceId: number, params: IngresoBalanceQueryParams = {}) => {
  return await axios
    .get<{ data: IngresoBalance[] }>(`${BASE_URL}/ingresos-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el ingreso del lote de balance solicitado
 * @param ingresoId id del ingreso de balance a recuperar
 */
const getIngresoBalance = async (ingresoId: number) => {
  return await axios
    .get<{ data: IngresoBalance }>(`${BASE_URL}/ingresos-balance/${ingresoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API el ingreso del lote de balance solicitado
 * @param ingresoId id del ingreso de balance a editar
 */
const editIngresoBalance = async (ingresoId: number, data: EditIngresoBalanceFormData) => {
  return await axios
    .patch<IngresoBalance>(`${BASE_URL}/ingresos-balance/${ingresoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api un nuevo ingreso de balance
 * @param data datos del ingreso a crear
 */
const newIngresoBalance = async (balanceId: number, data: NewIngresoBalanceFormData) => {
  return await axios
    .post<IngresoBalance>(BASE_URL + '/ingresos-balance', { balance_id: balanceId, ...data })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el ingreso de balance indicado (soft-delete)
 */
const deleteIngresoBalance = async (ingresoId: number) => {
  return await axios
    .delete<IngresoBalance>(`${BASE_URL}/ingresos-balance/${ingresoId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
        GASTOS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de gastos del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<GastoBalance[]>`.
 * @throws si el usuario no tiene permisos para ver los gastos del lote de balance.
 */
const getGastosBalance = async (balanceId: number, params: IngresoBalanceQueryParams = {}) => {
  return await axios
    .get<{ data: GastoBalance[] }>(`${BASE_URL}/gastos-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el gasto del lote de balance solicitado
 * @param gastoId id del gasto de balance a recuperar
 */
const getGastoBalance = async (gastoId: number) => {
  return await axios
    .get<{ data: GastoBalance }>(`${BASE_URL}/gastos-balance/${gastoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API el gasto del lote de balance solicitado
 * @param gastoId id del gasto de balance a editar
 */
const editGastoBalance = async (gastoId: number, data: EditGastoBalanceFormData) => {
  return await axios
    .patch<GastoBalance>(`${BASE_URL}/gastos-balance/${gastoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api un nuevo gasto de balance
 * @param data datos del gasto a crear
 */
const newGastoBalance = async (balanceId: number, data: NewGastoBalanceFormData) => {
  return await axios
    .post<GastoBalance>(BASE_URL + '/gastos-balance', { ...data, balance_id: balanceId })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el gasto de balance indicado (soft-delete)
 */
const deleteGastoBalance = async (gastoId: number) => {
  return await axios
    .delete<GastoBalance>(`${BASE_URL}/gastos-balance/${gastoId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
                CUENTAS BCE
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de cuentas BCE del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<CuentaBceBalance[]>`.
 * @throws si el usuario no tiene permisos para ver las cuentas BCE del lote de balance.
 */
const getCuentasBceBalance = async (
  balanceId: number,
  params: TipoCuentaBceBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoCuentaBceBalance[] }>(`${BASE_URL}/cuentas-bce-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API la cuenta BCE del lote de balance solicitado
 * @param cuentaBceId id de la cuenta BCE de balance a recuperar
 */
const getCuentaBceBalance = async (cuentaBceId: number) => {
  return await axios
    .get<{ data: TipoCuentaBceBalance }>(`${BASE_URL}/cuentas-bce-balance/${cuentaBceId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API la cuenta BCE del lote de balance solicitado
 * @param cuentaBceId id de la cuenta BCE de balance a editar
 */
const editCuentaBceBalance = async (cuentaBceId: number, data: EditCuentaBceBalanceFormData) => {
  return await axios
    .patch<TipoCuentaBceBalance>(`${BASE_URL}/cuentas-bce-balance/${cuentaBceId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api una nueva cuenta BCE de balance
 * @param data datos de la cuenta BCE a crear
 */
const newCuentaBceBalance = async (balanceId: number, data: NewTipoCuentaBceBalanceFormData) => {
  return await axios
    .post<TipoCuentaBceBalance>(BASE_URL + '/cuentas-bce-balance', {
      ...data,
      balance_id: balanceId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api la cuenta BCE de balance indicada (soft-delete)
 */
const deleteCuentaBceBalance = async (cuentaBceId: number) => {
  return await axios
    .delete<TipoCuentaBceBalance>(`${BASE_URL}/cuentas-bce-balance/${cuentaBceId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
        INVERSIONES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de inversiones del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<TipoInversionBalance[]>`.
 * @throws si el usuario no tiene permisos para ver las inversiones del lote de balance.
 */
const getInversionesBalance = async (
  balanceId: number,
  params: TipoInversionBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoInversionBalance[] }>(`${BASE_URL}/tipos-inversiones-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API la inversión del lote de balance solicitado
 * @param inversionId id de la inversión de balance a recuperar
 */
const getInversionBalance = async (inversionId: number) => {
  return await axios
    .get<{ data: TipoInversionBalance }>(`${BASE_URL}/tipos-inversiones-balance/${inversionId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API la inversión del lote de balance solicitado
 * @param inversionId id de la inversión de balance a editar
 */
const editInversionBalance = async (
  inversionId: number,
  data: EditTipoInversionBalanceFormData
) => {
  return await axios
    .patch<TipoInversionBalance>(`${BASE_URL}/tipos-inversiones-balance/${inversionId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api una nueva inversión de balance
 * @param data datos de la inversión a crear
 */
const newInversionBalance = async (balanceId: number, data: NewTipoInversionBalanceFormData) => {
  return await axios
    .post<TipoInversionBalance>(BASE_URL + '/tipos-inversiones-balance', {
      ...data,
      balance_id: balanceId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api la inversión de balance indicada (soft-delete)
 */
const deleteInversionBalance = async (inversionId: number) => {
  return await axios
    .delete<TipoInversionBalance>(`${BASE_URL}/tipos-inversiones-balance/${inversionId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
  BIENES DE USO
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de bienes de uso del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<TipoBienUsoBalance[]>`.
 * @throws si el usuario no tiene permisos para ver los bienes de uso del lote de balance.
 */
const getBienesUsoBalance = async (
  balanceId: number,
  params: TipoBienUsoBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoBienUsoBalance[] }>(`${BASE_URL}/bienes-uso-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el bien de uso del lote de balance solicitado
 * @param bienUsoId id del bien de uso de balance a recuperar
 */
const getBienUsoBalance = async (bienUsoId: number) => {
  return await axios
    .get<{ data: TipoBienUsoBalance }>(`${BASE_URL}/bienes-uso-balance/${bienUsoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API el bien de uso del lote de balance solicitado
 * @param bienUsoId id del bien de uso de balance a editar
 */
const editBienUsoBalance = async (bienUsoId: number, data: EditTipoBienUsoBalanceFormData) => {
  return await axios
    .patch<TipoBienUsoBalance>(`${BASE_URL}/bienes-uso-balance/${bienUsoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api un nuevo bien de uso de balance
 * @param data datos del bien de uso a crear
 */
const newBienUsoBalance = async (balanceId: number, data: NewTipoBienUsoBalanceFormData) => {
  return await axios
    .post<TipoBienUsoBalance>(BASE_URL + '/bienes-uso-balance', {
      balance_id: balanceId,
      ...data
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el bien de uso de balance indicado (soft-delete)
 */
const deleteBienUsoBalance = async (bienUsoId: number) => {
  return await axios
    .delete<TipoBienUsoBalance>(`${BASE_URL}/bienes-uso-balance/${bienUsoId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
              INTANGIBLES
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de intangibles del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<TipoIntangibleBalance[]>`.
 * @throws si el usuario no tiene permisos para ver los intangibles del lote de balance.
 */
const getIntangiblesBalance = async (
  balanceId: number,
  params: TipoIntangibleBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoIntangibleBalance[] }>(`${BASE_URL}/intangibles-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el intangible del lote de balance solicitado
 * @param intangibleId id del intangible de balance a recuperar
 */
const getIntangibleBalance = async (intangibleId: number) => {
  return await axios
    .get<{ data: TipoIntangibleBalance }>(`${BASE_URL}/intangibles-balance/${intangibleId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API el intangible del lote de balance solicitado
 * @param intangibleId id del intangible de balance a editar
 */
const editIntangibleBalance = async (
  intangibleId: number,
  data: EditTipoIntangibleBalanceFormData
) => {
  return await axios
    .patch<TipoIntangibleBalance>(`${BASE_URL}/intangibles-balance/${intangibleId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api un nuevo intangible de balance
 * @param data datos del intangible a crear
 */
const newIntangibleBalance = async (balanceId: number, data: NewTipoIntangibleBalanceFormData) => {
  return await axios
    .post<TipoIntangibleBalance>(BASE_URL + '/intangibles-balance', {
      ...data,
      balance_id: balanceId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el intangible de balance indicado (soft-delete)
 */
const deleteIntangibleBalance = async (intangibleId: number) => {
  return await axios
    .delete<TipoIntangibleBalance>(`${BASE_URL}/intangibles-balance/${intangibleId}`)
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
    RESULTADOS FINANCIEROS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de resultados financieros del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<TipoResultadoFinancieroBalance[]>`.
 * @throws si el usuario no tiene permisos para ver los resultados financieros del lote de balance.
 */
const getResultadosFinancierosBalance = async (
  balanceId: number,
  params: TipoResultadoFinancieroBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoResultadoFinancieroBalance[] }>(`${BASE_URL}/resultados-financieros-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API el resultado financiero del lote de balance solicitado
 * @param resultadoFinancieroId id del resultado financiero de balance a recuperar
 */
const getResultadoFinancieroBalance = async (resultadoFinancieroId: number) => {
  return await axios
    .get<{ data: TipoResultadoFinancieroBalance }>(
      `${BASE_URL}/resultados-financieros-balance/${resultadoFinancieroId}`
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API el resultado financiero del lote de balance solicitado
 * @param resultadoFinancieroId id del resultado financiero de balance a editar
 */
const editResultadoFinancieroBalance = async (
  resultadoFinancieroId: number,
  data: EditTipoResultadoFinancieroBalanceFormData
) => {
  return await axios
    .patch<TipoResultadoFinancieroBalance>(
      `${BASE_URL}/resultados-financieros-balance/${resultadoFinancieroId}`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api un nuevo resultado financiero de balance
 * @param data datos del resultado financiero a crear
 */
const newResultadoFinancieroBalance = async (
  balanceId: number,
  data: NewTipoResultadoFinancieroBalanceFormData
) => {
  return await axios
    .post<TipoResultadoFinancieroBalance>(BASE_URL + '/resultados-financieros-balance', {
      ...data,
      balance_id: balanceId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api el resultado financiero de balance indicado (soft-delete)
 */
const deleteResultadoFinancieroBalance = async (resultadoFinancieroId: number) => {
  return await axios
    .delete<TipoResultadoFinancieroBalance>(
      `${BASE_URL}/resultados-financieros-balance/${resultadoFinancieroId}`
    )
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
  MONEDAS EXTRANJERAS
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de monedas extranjeras del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<TipoMonedaExtranjeraBalance[]>`.
 * @throws si el usuario no tiene permisos para ver las monedas extranjeras del lote de balance.
 */
const getMonedasExtranjerasBalance = async (
  balanceId: number,
  params: TipoMonedaExtranjeraBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoMonedaExtranjeraBalance[] }>(`${BASE_URL}/monedas-extranjeras-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API la moneda extranjera del lote de balance solicitado
 * @param monedaExtranjeraId id de la moneda extranjera de balance a recuperar
 */
const getMonedaExtranjeraBalance = async (monedaExtranjeraId: number) => {
  return await axios
    .get<{ data: TipoMonedaExtranjeraBalance }>(
      `${BASE_URL}/monedas-extranjeras-balance/${monedaExtranjeraId}`
    )
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API la moneda extranjera del lote de balance solicitado
 * @param monedaExtranjeraId id de la moneda extranjera de balance a editar
 */
const editMonedaExtranjeraBalance = async (
  monedaExtranjeraId: number,
  data: EditMonedaExtranjeraBalanceFormData
) => {
  return await axios
    .patch<TipoMonedaExtranjeraBalance>(
      `${BASE_URL}/monedas-extranjeras-balance/${monedaExtranjeraId}`,
      data
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api una nueva moneda extranjera de balance
 * @param data datos de la moneda extranjera a crear
 */
const newMonedaExtranjeraBalance = async (
  balanceId: number,
  data: NewTipoMonedaExtranjeraBalanceFormData
) => {
  return await axios
    .post<TipoMonedaExtranjeraBalance>(BASE_URL + '/monedas-extranjeras-balance', {
      ...data,
      balance_id: balanceId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api la moneda extranjera de balance indicada (soft-delete)
 */
const deleteMonedaExtranjeraBalance = async (monedaExtranjeraId: number) => {
  return await axios
    .delete<TipoMonedaExtranjeraBalance>(
      `${BASE_URL}/monedas-extranjeras-balance/${monedaExtranjeraId}`
    )
    .then((response) => {
      return response.data;
    });
};

/* ---------------------------------------
        CAJA BANCO
 ---------------------------------------- */

/**
 * @description Recupera desde la API el listado de cajas y bancos del lote de balance solicitado
 * @param balanceId id del lote a recuperar
 * @returns Un objeto de tipo `PaginatedResponse<TipoCajaBancoBalance[]>`.
 * @throws si el usuario no tiene permisos para ver las cajas y bancos del lote de balance.
 */
const getCajasBancosBalance = async (
  balanceId: number,
  params: TipoCajaBancoBalanceQueryParams = {}
) => {
  return await axios
    .get<{ data: TipoCajaBancoBalance[] }>(`${BASE_URL}/cajas-bancos-balance`, {
      params: { ...params, balance_id: balanceId }
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Recupera desde la API la caja o banco del lote de balance solicitado
 * @param cajaBancoId id de la caja o banco de balance a recuperar
 */
const getCajaBancoBalance = async (cajaBancoId: number) => {
  return await axios
    .get<{ data: TipoCajaBancoBalance }>(`${BASE_URL}/cajas-bancos-balance/${cajaBancoId}`)
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @description Actualiza en la API la caja o banco del lote de balance solicitado
 * @param cajaBancoId id de la caja o banco de balance a editar
 */
const editCajaBancoBalance = async (cajaBancoId: number, data: EditCajaBancoBalanceFormData) => {
  return await axios
    .patch<TipoCajaBancoBalance>(`${BASE_URL}/cajas-bancos-balance/${cajaBancoId}`, data)
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Registra en la api una nueva caja o banco de balance
 * @param data datos de la caja o banco a crear
 */
const newCajaBancoBalance = async (balanceId: number, data: NewTipoCajaBancoBalanceFormData) => {
  return await axios
    .post<TipoCajaBancoBalance>(BASE_URL + '/cajas-bancos-balance', {
      ...data,
      balance_id: balanceId
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description Elimina en la api la caja o banco de balance indicada (soft-delete)
 */
const deleteCajaBancoBalance = async (cajaBancoId: number) => {
  return await axios
    .delete<TipoCajaBancoBalance>(`${BASE_URL}/cajas-bancos-balance/${cajaBancoId}`)
    .then((response) => {
      return response.data;
    });
};

const exportBalances = async (
  params: BalancesQueryParams & { separator?: string; id?: number } = {}
) => {
  return await axios
    .get(`${API_URL}/exportar/balances`, { params, responseType: 'blob' })
    .then((response) => {
      return response.data;
    });
};

export const BalanceService = {
  getBalances,
  getBalance,
  newBalance,
  editBalance,
  deleteBalance,

  exportBalances,

  getBalancesEstados,

  getIngresoBalance,
  getIngresosBalance,
  editIngresoBalance,
  newIngresoBalance,
  deleteIngresoBalance,

  getGastoBalance,
  getGastosBalance,
  editGastoBalance,
  newGastoBalance,
  deleteGastoBalance,

  getCuentaBceBalance,
  getCuentasBceBalance,
  editCuentaBceBalance,
  newCuentaBceBalance,
  deleteCuentaBceBalance,

  getInversionBalance,
  getInversionesBalance,
  editInversionBalance,
  newInversionBalance,
  deleteInversionBalance,

  getBienUsoBalance,
  getBienesUsoBalance,
  editBienUsoBalance,
  newBienUsoBalance,
  deleteBienUsoBalance,

  getIntangibleBalance,
  getIntangiblesBalance,
  editIntangibleBalance,
  newIntangibleBalance,
  deleteIntangibleBalance,

  getResultadoFinancieroBalance,
  getResultadosFinancierosBalance,
  editResultadoFinancieroBalance,
  newResultadoFinancieroBalance,
  deleteResultadoFinancieroBalance,

  getMonedaExtranjeraBalance,
  getMonedasExtranjerasBalance,
  editMonedaExtranjeraBalance,
  newMonedaExtranjeraBalance,
  deleteMonedaExtranjeraBalance,

  getCajaBancoBalance,
  getCajasBancosBalance,
  editCajaBancoBalance,
  newCajaBancoBalance,
  deleteCajaBancoBalance
};

export default BalanceService;
